import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './SmallList.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight, faPlus, faCalendar} from "@fortawesome/pro-light-svg-icons";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";
import * as mainStyle from "../../index.module.scss";
import {InlineWidget} from "react-calendly";
import {useState} from "react";
import {Client} from "../../../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";

const mapState = ({member, auth}) => ({
    member,
    auth
})
const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type SmallListProps = PropsFromRedux & {
    title: string,
    description: string,
    mediaPosLeft: string,
    image: string,

    botInfoBoxHeadline: string,
    botInfoBoxDescription: string,

    listItem1Headline: string,
    listItem2Headline: string,
    listItem3Headline: string,
    listItem4Headline: string,

    listItem1Description: string,
    listItem2Description: string,
    listItem3Description: string,
    listItem4Description: string,

}


function SmallList(props: SmallListProps) {
    const [showCalendly, setShowCalendly] = useState(false)

    return <>
        <Row className={mainStyle.box}>
            <Row>
                <Col lg={7}>
                    <div className={style.smallListElement + " " + style.headline}>
                        <h2 className={style.headline}>{props.title}</h2>
                    </div>
                </Col>
            </Row>
            <Col lg={5} className={`${props.mediaPosLeft ?  style.paddingLeft : style.paddingRight} order-last ` + style.imgBox}>
                <Row className={style.listContainer}>
                    <Col lg={12}>
                        <div className={style.description}>{props.description}</div>
                    </Col>
                    <Col lg={12} className={"d-flex " + style.customBottom}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem1Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem1Description}
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} className={"d-flex " + style.customBottom}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem2Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem2Description}
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} className={"d-flex " + style.customBottom}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem3Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem3Description}
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} className={"d-flex " + style.customBottom}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem4Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem4Description}
                            </div>
                        </div>
                    </Col>
                </Row>
                {showCalendly ? <InlineWidget url="https://calendly.com/fairstaerkt?primary_color=f080c5" /> : null}

                <Row  className={"d-flex align-items-center justify-content-start"}>
                    <Button className={style.customBTN} onClick={() => {setShowCalendly(true);
                        if(props.member.MemberID) {
                        Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData').then((res) => {
                            // @ts-ignore
                            if (window.dataLayer) {
                                let dataLayerPush = {
                                    'cicData': {
                                        'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                        'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                        'cic_plz': res.data.plz ?? 'NOPLZ',
                                        'cic_location': res.data.City ?? 'NOCITY',
                                    },
                                    'event': 'fs.landingpage_calender'
                                };

                                console.log(dataLayerPush)

                                // @ts-ignore
                                window.dataLayer.push(dataLayerPush);
                            }
                        })
                        } else {
                            // @ts-ignore
                            if (window.dataLayer) {
                                let dataLayerPush = {
                                    'cicData': {
                                        'cic_member_mail': 'user not logged in',
                                        'cic_country': 'NOCOUNTRY',
                                        'cic_plz': 'NOPLZ',
                                        'cic_location': 'NOCITY',
                                    },
                                    'event': 'fs.landingpage_calender'
                                };

                                console.log(dataLayerPush)

                                // @ts-ignore
                                window.dataLayer.push(dataLayerPush);
                            }
                        }
                    }}>

                        <Row>
                            <Col lg={1} xs={2} className={"d-flex align-items-center justify-content-center"}><FontAwesomeIcon className={style.icon} icon={faCalendar}/></Col>
                            <Col lg={11} xs={10} className={style.btnText}>KOSTENLOSEN BERATUNGSTERMIN VEREINBAREN</Col>
                        </Row>
                    </Button>
                </Row>

            </Col>

            <Col lg={7} className={props.mediaPosLeft ? style.paddingRight : style.paddingLeft}>
                <div className={"position-relative " + style.imageContainer}>
                    <img src={props.image} className={style.image}/>
                    <div className={style.botInfoBox + " align-items-start " + style.infoBox}>
                        <Row className={"d-flex justify-content-center align-items-center"}>
                            <Col xs={2}>
                                <StaticImage src={"https://api.fairstaerkt.at/assets/LandingPageIcons/Komponente_9_1.png?vid=3"}
                                             className={style.infoBoxIMG}
                                             placeholder={"traceSVG"} alt={"background"}/>
                            </Col>
                            <Col xs={10}>
                                <div className={style.infoBoxHeadline}>
                                    {props.botInfoBoxHeadline}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className={style.infoBoxBotDescription}>
                                {props.botInfoBoxDescription}
                            </div>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </>
        ;
}

export default connector(SmallList);
